import React from "react";
import { Link } from 'gatsby'
import appData from "data/app.json";

const Footer = ({ hideBGCOLOR }) => {
  return (
    <footer className={`${!hideBGCOLOR ? "sub-bg" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="item md-mb50">
              <div className="logo">
                <img src={appData.lightLogo} alt="" />
              </div>
              <div className="social">
                <a target="_blank" href="https://www.linkedin.com/company/imediarc">
                  <i className="fab fa-linkedin-in"></i>
                </a>

                <a target="_blank" href="https://www.instagram.com/imediarc/">
                  <i className="fab fa-instagram"></i>
                </a>
                <a target="_blank" href="https://www.facebook.com/imediarc">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </div>
              <div className="copy-right">

                <p>MEDIARC INTERACTIVE PVT. LTD.</p>

              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item md-mb50">
              <div className="title mb-30">
                <h6>Links</h6>
              </div>
              <ul>
                <li>
                  <div className="cont">
                    <Link to={`/`}  className="nav-link">
                      Home
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="cont">
                    <Link to={`/about`} className="nav-link">
                      About
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="cont">
                    <Link to={`/showcase`} className="nav-link">
                      Showcase
                    </Link>

                  </div>
                </li>
                <li>
                  <div className="cont">
                    <Link to={`/product`} className="nav-link">
                      Product
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="cont">
                    <Link to={`/contact`} className="nav-link">
                      Contact Us
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item md-mb50">
              <div className="title mb-30">
                <h6>Contact Us</h6>
              </div>
              <ul>
                <li>
                  <span className="icon pe-7s-map-marker"></span>
                  <div className="cont">
                    <h6>Address</h6>
                    <p>Mediarc interactive Pvt. Ltd.</p>
                    <p>New Delhi, India</p>
                  </div>
                </li>
                <li>
                  <span className="icon pe-7s-mail"></span>
                  <div className="cont">
                    <h6>Email</h6>
                    <p>contact@imediarc.com</p>
                  </div>
                </li>
                <li>
                  <span className="icon pe-7s-call"></span>
                  <div className="cont">
                    <h6>Phone</h6>
                    <p>+011 40364982 </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
