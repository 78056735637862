import React, { useCallback, useEffect } from "react";
import { loadFull } from "tsparticles";
import ContentHeaderDate from "data/sections/contact-header.json";

const ContactHeader = ({ sliderRef, blackStar }) => {
  useEffect(() => {
    setTimeout(() => {
      document.querySelector('#particles-js canvas')?.style.removeProperty('position');
    }, 0);
  }, []);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <header
      ref={sliderRef}
      className="pages-header circle-bg valign position-re"
    >
      <div className="container" style={{zIndex:10}}>
        <div className="row justify-content-center">
          <div className="col-lg-9 col-md-11">
            <div className="capt">
              <div className="text-center contact-heading">
                {/* <h1 className=" mb-10 fw-700">
                  {ContentHeaderDate.title.first} 
                  {ContentHeaderDate.title.second}
                </h1> */}
                <p class="title-slide fw-300 home-title-left" style={{color:"#ffffff", textAlign:"center"}}> {ContentHeaderDate.title.first}  <span class="title-slide-sec">{ContentHeaderDate.title.second} </span> {ContentHeaderDate.title.third} <span class="title-slide-sec">{ContentHeaderDate.title.fourth} </span> </p>
                {/* <p>{ContentHeaderDate.content}</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Particles
        id="particles-js"
        init={particlesInit}
        options={blackStar ? particlesBlackConfig : particlesConfig}
      /> */}
      <div className="bg-img valign bg-contact"
       style={{ backgroundImage: `url("/img/slid/contact2.jpg")` }}
 
       data-overlay-dark="6"
      
      >
        <div className="gradient-circle"></div>
        <div className="gradient-circle two"></div>
      </div>
    </header>
  );
};

export default ContactHeader;
