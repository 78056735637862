import React from "react";
import { Formik, Form, Field } from "formik";
import ContactFromDate from "data/sections/form-info.json";

const ContactForm = () => {
  const messageRef = React.useRef(null);
  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validatePhone(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[6789]\d{9}$/i.test(value)) {
      error = "Invalid mobile number";
    }
    return error;
  }
  function validateName(value) {
    console.log(value)
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[a-zA-Z ]{2,30}$/i.test(value)) {
      error = "Invalid name";
    }
    console.log(error)
    return error;
  }

  const sendMessage = (ms) => new Promise((r) => setTimeout(r, ms));

  return (
    <section className="contact section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="form md-mb50">
              <h4 className="fw-700 color-font mb-50">Get In Touch.</h4>
              <Formik
                initialValues={{
                  name: "", email: "", message: "", phone: ""
                }}
                onSubmit={async (values) => {
                  console.log(values)
                  await sendMessage(500);
                  alert(JSON.stringify(values, null, 2));
                  // show message

                  messageRef.current.innerText =
                    "Your Message has been successfully sent. I will contact you soon.";
                  // Reset the values
                  // values.name = "";
                  // values.email = "";
                  // values.message = "";
                  // values.phone = "";
                  // clear message
                  const response = await   fetch('https://www.adzenic.com/sendemail_api.php', {
                    method: 'POST',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      // 'Access-Control-Allow-Origin': 'http://localhost:8000',
                      // 'Access-Control-Allow-Credentials': 'true'
                    },
                    body: JSON.stringify({
                      name: values.name,
                      email: values.email,
                      phone: values.phone,
                      subject: "",
                      message:  values.message
                    }),
                  })
                  const json = await response.json();
                  console.log(json)
                  // setTimeout(() => {
                  //   messageRef.current.innerText = ''
                  // }, 2000000)
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange,
                  handleBlur, isSubmitting }) => (
                  <Form id="contact-form" onSubmit={handleSubmit} >
                    <div className="messages" ref={messageRef}></div>
                    <div className="controls">
                      <div className="form-group">
                        <Field
                          id="form_name"
                          type="text"
                          name="name"
                          placeholder="Name"
                          required="required"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          validate={validateName}
                        />
                        {errors.name && touched.name && (
                          <div className="formError">{errors.name}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <Field
                          validate={validateEmail}
                          id="form_email"
                          type="email"
                          name="email"
                          placeholder="Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {errors.email && touched.email && (
                          <div className="formError">{errors.email}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <Field
                          validate={validatePhone}
                          id="form_mobile"
                          type="tel"
                          name="phone"
                          placeholder="Mobile number"
                          pattern="[6789][0-9]{9}"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                        />
                        {errors.phone && touched.phone && (
                          <div className="formError">{errors.phone}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <Field
                        as="textarea"
                        id="form_message"
                        name="message"
                        placeholder="Message"
                        rows="2"
                        // required="required"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                      />
                    </div>

                    <button type="submit" disabled={isSubmitting} className="butn bord">
                      <span>Send Message</span>
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="cont-info">
              <h4 className="fw-700 color-font mb-50">Contact Info.</h4>
              <h3 className="wow" data-splitting>
                {ContactFromDate.title}
              </h3>
              <div className="item mb-40">
                <h5>
                  <a href="#0">{ContactFromDate.email}</a>
                </h5>
                <h5>{ContactFromDate.phone}</h5>
              </div>
              <h3 className="wow" data-splitting>
                Visit Us.
              </h3>
              <div className="item">
                <h6>
                  {ContactFromDate.location.first}
                  <br />
                  {ContactFromDate.location.second}
                </h6>
              </div>
              <div className="social mt-50">
                <a target="_blank" href="https://www.linkedin.com/company/imediarc" className="icon">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a target="_blank" href="https://www.instagram.com/imediarc/" className="icon">
                  <i className="fab fa-instagram"></i>
                </a>
                <a target="_blank" href="https://www.facebook.com/imediarc" className="icon">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;